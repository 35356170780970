<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="unitModal"
    >   
        <CForm v-if="form.id > 0">
            <CRow>
              <CCol md="12">
                <CInput
                  label="Código:"
                  :lazy="false"
                  :value.sync="$v.form.code.$model"
                  :isValid="checkIfValid('code')"
                  placeholder=""
                  autocomplete="code"
                  invalidFeedback="Este campo debe ser entre 2 y 5 caracteres."
              />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              />
              </CCol>
            </CRow>
        </CForm>
        <CForm v-if="!(form.id > 0)">
          <CRow>
            <CCol sm="10">
              <CInput
                label="Buscador:"
                :lazy="false"
                :value.sync="$v.form.search.$model"
                placeholder=""
                autocomplete="search"
              />
            </CCol>
            <CCol sm="2" style="padding-top: 30px !important; text-align: right;">
              <CButton @click="search" :color="color">Buscar</CButton>
            </CCol>
          </CRow>
          <CRow v-if="items.length > 0">
            <CCol md="12">
              <CDataTable
                hover
                border
                fixed
                :items="items"
                :fields="fields"
                :items-per-page="30"
                pagination
              >
                <template #code="{item}"><td class="w100 text-center">{{item.code}}</td></template>
                <template #actions="{item}">
                  <td class="table_actions">
                    <CSwitch
                        v-model="item.selected"
                        :checked.sync="item.selected"
                        class="mx-1"
                        color="success"
                        size="lg"
                        name="switch1"
                        @update:checked="selectUnit(item)"
                      />
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import units_ws from '../../../services/units';
import store from '../../../store';

export default { 
  name: 'UnitModal',
  props: {

  },
  
  data () {
    return {
      unitModal: false,
      title: "Nueva Unidad", 
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'code', label: 'Código SAT'},
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      items: [],
      selections: false
    }
  },
  methods: {
    storeModal () {
      this.items = [];
      
      this.unitModal = true;
      this.color = "info";
      this.title = 'Nueva Unidad';
      this.isEdit = false;
      this.submitted = false;

      this.form = this.getEmptyForm();
    },
    updateModal (item) {
      this.unitModal = true;
      this.color = "warning";
      this.title = 'Editar Unidad';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          code: item.code,
          name: item.name
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    async search () {
      this.items = [];
      
      let response = await units_ws.getSelectedUnits(this.form.search); 

      if(response.type == "success"){
        this.items = response.data;

        this.items.forEach(function(item){
          item.selected = item.selected > 0 ? true : false;
        });
      }
      else{
        this.showToast(response.type, response.message);
      }
    },
    async selectUnit (item) {
      this.selections = true;

      if(item.selected){
        let response = await units_ws.addSelectedUnit(item);

        this.$emit("store", response.data);

        this.showToast(response.type, response.message);
      }
      else{
        let response = await units_ws.deleteSelectedUnit(item.code); 

        this.$emit("store", this.form );

        this.showToast(response.type == "success" ? "danger" : response.type, response.message);
      }
    },
    closeModal () {
      this.unitModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          code: '',
          name: '',
          search: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      code: {},
      name: {},
      search: {},
    }
  },
}
</script>